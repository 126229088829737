var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "text-muted mb-0"
  }, [_vm._t("top")], 2), _c('h2', {
    staticClass: "text-primary mb-0"
  }, [_vm._t("title")], 2), _c('h4', {
    staticClass: "mb-0"
  }, [_vm._t("bottom")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }